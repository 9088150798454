/* eslint-disable react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '@/components/Seo'
import Blocks from '@/utils/Blocks'
import { cleanPropsWithRelations } from '@/utils'
import useConstant from '@/hooks/useConstant'
/* eslint-disable no-unused-vars */
import {
	caseStudyQuery,
	quoteQuery,
	postThumbQuery,
	useCaseQuery
} from '@/queries'
/* eslint-enable no-unused-vars */
import * as T from '@/types'

function UseCase({
	pageContext: { media, wordpress_id },
	data: {
		page: { blocks, yoast, featured_media },
		caseStudies: { nodes: caseStudies },
		posts: { nodes: posts },
		quotes: { nodes: quotes }
	}
}) {
	const data = useConstant(() =>
		cleanPropsWithRelations(blocks, media, {
			'acf/case-study-card-slider': {
				data: [...caseStudies, ...posts],
				key: 'case_studies'
			},
			'acf/quote-collection-block': { data: quotes, key: 'quotes' }
		})
    )

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<Blocks data={data} media={media} currentPage={wordpress_id} />
		</>
	)
}

UseCase.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired,
		caseStudies: T.caseStudies.isRequired,
		posts: T.posts.isRequired,
		quotes: T.quotes.isRequired
	})
}

export const query = graphql`
	query UseCaseEntry($path: String!) {
		quotes: allWordpressWpQuotes(limit: 100) {
			nodes {
				...quoteQuery
			}
		}
		page: wordpressWpUseCase(path: { eq: $path }) {
			...useCaseQuery
		}

		caseStudies: allWordpressWpCaseStudy(filter: { slug: { ne: "template" } }) {
			nodes {
				...caseStudyQuery
			}
		}
		posts: allWordpressPost(filter: { slug: { ne: "template" } }) {
			nodes {
				...postThumbQuery
			}
		}
	}
`
export default UseCase
